import React from "react";

import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import WritingItem from "../components/WritingItem";

function WritingPage( { data } ) {
  const { allMdx } = data; // data.mdx holds your post data
  const articles = allMdx.edges;
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Writing"
      />
      {/* Page Container */}
      <div className="mt-16 max-w-2xl mx-auto">
        <h2 className="text-2xl font-semibold">Writing</h2>
        <div className="mt-16 text-lg grid grid-cols-1 gap-y-10 gap-x-10">
        {articles.map((article) => (
              <WritingItem key="" article={article} />
            ))}
        </div>
      </div>
    </Layout>
  );
}

export default WritingPage;

export const query = graphql`
query {
  allMdx (sort: {
    fields: frontmatter___date
    order: DESC
  }) {
    edges {
      node {
        slug
        frontmatter {
          title
          description
          date(formatString: "MMMM YYYY")
        }
      }
    }
  }
}
`;